import React, { Component, Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const SeminarLogin = React.lazy(() => import('./views/pages/seminar/Login'));
const SeminarMe = React.lazy(() => import('./views/pages/seminar/Me'));
const SeminarAnnouncement = React.lazy(() => import('./views/pages/seminar/Announcement'));
const SeminarAdmin = React.lazy(() => import('./views/pages/seminar/Admin'));

//const Login = React.lazy(() => import('./views/pages/login/Login'))
//const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" name="Seminar Login" element={<SeminarLogin />} />
            <Route exact path="/me" name="Seminar Me" element={<SeminarMe />} />
            <Route exact path="/announcement" name="Seminar Announcement" element={<SeminarAnnouncement />} />
            <Route exact path="/admin" name="Seminar Admin" element={<SeminarAdmin />} />

            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    );
  }
}

export default App;
