import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import axios from 'axios'
import TagManager from 'react-gtm-module';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// Initialize GTM with your GTM ID
const tagManagerArgs = {
  gtmId: 'GTM-MR9LVCRH', // Replace this with your actual GTM ID
};

TagManager.initialize(tagManagerArgs);

/*
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
)
*/

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
